// * {
//   outline: 1px solid red;
// }

// * {
//   background-color: rgba(255, 0, 0, 0.2);
// }
// * * {
//   background-color: rgba(0, 255, 0, 0.2);
// }
// * * * {
//   background-color: rgba(0, 0, 255, 0.2);
// }
// * * * * {
//   background-color: rgba(255, 0, 255, 0.2);
// }
// * * * * * {
//   background-color: rgba(0, 255, 255, 0.2);
// }
// * * * * * * {
//   background-color: rgba(255, 255, 0, 0.2);
// }
// * * * * * * * {
//   background-color: rgba(255, 0, 0, 0.2);
// }
// * * * * * * * * {
//   background-color: rgba(0, 255, 0, 0.2);
// }
// * * * * * * * * * {
//   background-color: rgba(0, 0, 255, 0.2);
// }

:root {
  --nav-height: 50px;
  --background2: #ecf0f4;
  --active-color: #1dc4e9;
  --header-height: 40px;
  --notif-color: #17a2b8;
  --ag-font: 'Open Sans', sans-serif;
  // fonts: "Open Sans", sans-serif;"Roboto";"Lato";"Montserrat";"Oswald"  Calibri
  //btn colors
  --danger-color: #dc3545;
  --success-color: #28a745;
  --warning-color: #e1ad01;
  --border-color-light: rgba(0, 0, 0, 0.3);
  --border-color-dark: #39465e;
  --select: #2196f3;
  --white-color: #fff;
  --hover-color: rgba(4, 169, 245, 0.1);
  --box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  --semi-darkborder: #8a8886;
}
:root[color-mode='light'] {
  --color: #a9b7d0;
  --background-color: #3f4d67;
  //alt #37003c
  --submenu-color: #39465e;
  --nav-color: #f4f7fa;
  --black: black;
  --white: white;
  --pure-white: white;
  --pure-black: black;
  --background-color-alt: white;
  --notif-alt: white;
  --ag-selected: #17a2b8;
  --ag-odd: #dee2e638;
  --ag-hover-color: #758ab6;
  --dropdown: #fff;
  --table-button: #3f4d67;
  --border-color: #dee2e6;
  --light-color: #f4f7fa;
  --text-color: #111;
  --not-found: #ecf1fa;
  --settings-tab: #e1fafa;
  --multi-tab-color: #ebebeb;
}

:root[color-mode='dark'] {
  --background-color: #1b1b1b;
  --submenu-color: #111;
  --color: white;
  --nav-color: #252525;
  --black: #f3f3f3;
  --white: #2e2e2e;
  --pure-white: black;
  --pure-black: white;
  --background-color-alt: #343a40;
  --light-color: #444;
  --text-color: #b3b3b3;
  --border-color: #2e2e2e;
  --ag-hover-color: #3d4749;
  --ag-selected: #17a2b8;
  --dropdown: #444;
  --notif-alt: #222;
  --table-button: purple;
  --ag-odd: #424242;
  --not-found: #222;
  --settings-tab: #17a2b8;
  --multi-tab-color: #444;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.ag-theme-alpine {
  --ag-row-hover-color: hsl(207, 90%, 94%);
  --ag-selected-row-background-color: hsl(207, 87%, 86%);
}

/* Hide right header and spacer */
.ag-pinned-right-header,
.ag-horizontal-right-spacer {
  width: 0 !important;
  min-width: 0 !important;
}

/* Add absolute position so that action buttons column will appear on top of other columns. pointer-events: none to pass on mousemove event to behind columns */
.ag-pinned-right-cols-container {
  position: absolute !important;
  left: 0;
  pointer-events: none;
}
/* Reset pointer-events so that click can happen on action buttons */
.ag-pinned-right-cols-container * {
  pointer-events: initial;
}

/* Hide border of right-cols-container */
.ag-pinned-right-cols-container .ag-cell {
  border: none !important;
}

/* Show action buttons only for row that is being hovered. For rows which are not being hovered, hide them by setting their width and padding to 0.*/
.ag-pinned-right-cols-container .ag-row:not(.ag-row-hover),
.ag-pinned-right-cols-container .ag-row:not(.ag-row-hover) .ag-cell {
  width: 0 !important;
  padding: 0 !important;
}

body {
  font-family: 'Open Sans', sans-serif;
  backface-visibility: hidden;
  color: var(--pure-black);
}

a {
  color: inherit;
  text-decoration: none;
}

li {
  list-style-type: none;
  display: list-item;
  text-align: -webkit-match-parent;
}

button {
  display: inline-block;
  text-align: center;
  text-indent: 0px;
  text-shadow: none;
  letter-spacing: normal;
  word-spacing: normal;
  text-rendering: auto;
  font-size: inherit;
  line-height: inherit;
  &:not(:disabled) {
    cursor: pointer;
  }
}

.App {
  background-color: var(--nav-color);
  overflow: hidden;
  height: 100vh;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.navbringer {
  position: fixed;
  width: 2px;
  height: 100vh;
  color: black;
  z-index: 100;
}

//keep this in order
.collapse.right-side {
  margin-left: 75px;
}
.nonav.right-side {
  margin-left: 0;
}

.right-side {
  position: relative;
  margin-left: 264px;
  clear: both;
  float: unset;
  right: 0;
  height: 100vh;
  background-color: var(--nav-color);
  transition: all 0.3s ease-in-out;
}

.content {
  position: relative;
  // height: calc(100vh - var(--header-height));
  height: 100%;
  //very important
  overflow: hidden;
}

.table {
  height: 100%;
  display: flex;
  flex-direction: column;
}

div.disable {
  pointer-events: none;
  opacity: 0.7;
}

.sidetable {
  justify-content: space-evenly;
  min-height: 90%;
  display: flex;
}

.table-header {
  position: absolute;
  z-index: 8;
  width: 100%;
  text-align: center;
  top: -1.7rem;
}
// Button SLick Style

.btn-slick {
  background-color: var(--light-color) !important;
  border: 1px solid var(--border-color-light) !important;
  color: var(--text-color) !important;
  padding: 8px 6px !important;
  font-size: 14px !important;
  margin-right: 5px !important;
  outline: none !important;
  cursor: pointer !important;
  transition-duration: 0.4s !important;
}

.btn-slick-success {
  background-color: var(--success-color) !important;
  border: 1px solid var(--success-color) !important;
  color: var(--light-color) !important;
}

.btn-slick-danger {
  background-color: var(--danger-color) !important;
  border: 1px solid var(--danger-color) !important;
  color: var(--light-color) !important;
}

.btn-slick-primary {
  background-color: var(--active-color) !important;
  border: 1px solid var(--active-color) !important;
  color: var(--light-color) !important;
}

.btn-slick-warning {
  background-color: var(--warning-color) !important;
  border: 1px solid var(--warning-color) !important;
  color: var(--light-color) !important;
}

.btn-outline-primary,
.btn-outline-success,
.btn-outline-warning,
.btn-outline-danger {
  background-color: var(--light-color) !important;
  color: var(--text-color) !important;
}

.btn-outline-primary i {
  color: var(--active-color) !important;
}

.btn-outline-warning i {
  color: var(--warning-color) !important;
}

.btn-outline-success i {
  color: var(--success-color) !important;
}

.btn-outline-danger i {
  color: var(--danger-color) !important;
}

.outline-none {
  border: 1px solid var(--light-color) !important;
}

.btn-slick:hover,
.btn-slick:active {
  border: 1px solid var(--border-color-dark) !important;
  outline: none !important;
}

.btn-outline-primary:hover {
  border: 1px solid var(--active-color) !important;
}

.btn-outline-warning:hover {
  border: 1px solid var(--warning-color) !important;
}

.btn-outline-success:hover {
  border: 1px solid var(--success-color) !important;
}

.btn-outline-danger:hover {
  border: 1px solid var(--danger-color) !important;
}

// hide watermark
.ag-watermark .ag-opacity-zero .ag-hidden {
  display: none !important;
}

.ag-watermark {
  display: none !important;
}

.ag-hidden {
  display: none !important;
}

.ag-opacity-zero {
  display: none !important;
}

//perfect scrollbar
.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  &:hover {
    background-color: transparent !important;
  }
}

.ps .ps__rail-y.ps--clicking {
  background-color: transparent !important;
}

// utility
.pl-10 {
  padding-left: 10px;
}

.icon-danger {
  color: var(--danger-color);
}

.icon-success {
  color: var(--success-color);
}

.context-text:hover .icon-danger,
.context-text:hover .icon-success,
.context-text:hover {
  color: var(--nav-color) !important;
}

.context-text.disabled:hover .icon-danger,
.context-text.disabled:hover .icon-success,
.context-text.disabled {
  color: var(--text-color) !important;
}

//SLick Spinner
.loader-box {
  flex-direction: column !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.slick-loader {
  border: 2px solid var(--nav-color); /* Light grey */
  border-top: 2px solid var(--active-color); /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

i.feather.icon-loader {
  //wont rotate without this
  display: inline-block;
  animation: spin 1s linear infinite;
  color: var(--active-color);
}

.loader-text {
  margin-top: 10px;
  font-size: 12px;
  color: var(--pure-black);
}

// Alert Styling
.slick-alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 8px;
  font-size: 14px;
  text-transform: capitalize;
  z-index: 40;
  color: var(--light-color);
  border: 1px solid var(--border-color-light);
  pointer-events: auto;
}

.slick-alert .alert-icon {
  width: 10px;
  margin: 0 10px;
}

.alert-primary {
  background-color: var(--active-color);
  color: var(--light-color);
}
.alert-primary-outline {
  background-color: var(--light-color);
  border: 1px solid var(--active-color);
  color: var(--text-color);
}

.alert-primary-outline .outline {
  color: var(--active-color);
}

.alert-success {
  background-color: var(--success-color);
  color: var(--light-color);
}

.alert-success-outline {
  background-color: var(--light-color);
  border: 1px solid var(--success-color);
  color: var(--text-color);
}

.alert-success-outline .outline {
  color: var(--success-color);
}

.alert-warning {
  background-color: var(--warning-color);
  color: var(--light-color);
}

.alert-warning-outline {
  background-color: var(--light-color);
  border: 1px solid var(--warning-color);
  color: var(--text-color);
}

.alert-warning-outline .outline {
  color: var(--warning-color);
}

.alert-danger {
  background-color: var(--danger-color);
  color: var(--light-color);
}

.alert-danger-outline {
  background-color: var(--light-color);
  border: 1px solid var(--danger-color);
  color: var(--text-color);
}

.alert-danger-outline .outline {
  color: var(--danger-color);
}

.alert-default {
  background-color: var(--light-color);
  color: var(--text-color);
}

//Slick Select
.slickselect-box,
.slick-search-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 15px;
  font-size: 14px;
}

.slickselect-label,
.slick-search-label {
  font-size: 14px;
  color: var(--black);
}

.slick-select,
.slick-search {
  background-color: var(--light-color) !important;
  border: 1px solid var(--border-color-light) !important;
  color: var(--text-color) !important;
  padding: 4px 2px !important;
  font-size: 14px !important;
  margin-left: 5px !important;
  outline: none !important;
  cursor: pointer !important;
  width: 250px;
  transition-duration: 0.4s !important;
}

.showcase {
  border-top: 1px solid var(--border-color-light);
  display: flex;
  height: 100%;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  padding: 6px 15px;
  color: var(--pure-black);
}

.slick-modal {
  background-color: var(--white);
  position: absolute;
  top: 1%;
  left: 10%;
  // padding: 5px 0px;
  width: 80%;
  height: 80%;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.slick-modal .btn-slick {
  padding: 4px 6px !important;
  font-size: 13px !important;
}
.view-modal {
  left: 5%;
  width: 90%;
}

.slick-modal .table {
  background-color: var(--nav-color);
  height: 100%;
  border-radius: 5px;
  margin-bottom: 0px;
}

.modal-content {
  height: 100%;
}

.modal-head {
  // height: 35px;
  padding: 2px;
}

.modal-head .header,
.modal-head .action {
  min-height: 25px;
}

.modal-head .header {
  position: relative;
  justify-content: unset;
}

.modal-title {
  margin-right: 20px;
  font-size: 14px;
}

.modal-head .action {
  width: 50%;
  margin-left: 10px;
}

.dateModalContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.dateBox-container {
  width: 330px;
  padding: 20px;
  border-radius: 5px;
  gap: 10px;
  background: var(--white-color);
  height: 140px;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  box-shadow: 3px 5px 15px 2px rgba(0, 0, 0, 0.5);
  position: relative;
}
.dateBoxIcon {
  position: absolute;
  top: 0px;
  right: 4px;
  padding: 1px;
  cursor: pointer;
}
.draggable {
  margin: -5px 0 0 -10px;
  padding: 10px 7px 10px 7px;
}

.filter-box {
  margin: 6px 3px;
  display: flex;
  flex-direction: column;
}

.filter-box .filter-input {
  margin: 3px 0;
  padding: 6px 8px;
  outline: none;
  border: 1px solid var(--border-color-light);
}

.filter-box .filter-input:focus {
  outline: none;
}

.filter-box .filter-label {
  font-size: 12px;
}

.modal-search {
  .search-input {
    margin: 3px 0;
    padding: 4px 4px;
    outline: none;
    border: 1px solid var(--border-color-light);
    font-size: 12px;
  }

  .search-input[type='date'] {
    padding: 2.8px 4px;
  }

  .modal-select {
    outline: none;
    border: none;
    border: 1px solid var(--border-color-light);
    border-right: none;
    padding: 3.4px 4px;
    font-size: 12px;
  }

  margin-right: 0.5rem;
}

.inline-date {
  display: flex;
  align-items: center;
  margin: 0 10px;

  .date-label {
    font-size: 13px;
    margin-right: 5px;
  }
}

.dateBox {
  display: flex;
  border: 1px solid var(--semi-darkborder);

  .date-display {
    outline: none;
    border: none;
    width: 50px;
    text-align: center;
  }

  .date-display.active-date {
    border: none;
    margin-right: 0;
  }

  .date-display:disabled {
    color: var(--text-color);
    cursor: default;
    background-color: var(--border-color);
  }
}

.input-icon {
  margin-left: -2rem !important;
  padding: 0.3rem 0.2rem;
  cursor: pointer;
}
.text-warning {
  color: orange;
}
.options-btn {
  padding: 0.3rem;
  border: none;
  background: none;
  border: 1px solid green;
  font-size: 0.8rem;
}

.btn-default {
  background-color: #f8f9fa;
  border: 1px solid gray;
}

.table-options {
  padding: 0.3rem 0.5rem;
  height: 2rem;
  background-color: #f1f1f1;
  display: flex;
}
.options-container::before {
  content: '';
  position: absolute;
  left: -0.8rem;
  top: 4.5rem;
  // background: #ffffff;
  transform: rotate(90deg);
  border-radius: 5px;
  border-top: 10px solid #ffffff;
  border-right: 10px solid transparent;
  border-bottom: 0 solid transparent;
  border-left: 10px solid transparent;
}
.options-container {
  position: absolute;
  z-index: 3;
  min-width: 11rem;
  top: -3.5rem;
  left: 4.5rem;
  background: #ffffff;
  // border: 1px solid black;
  padding: 1rem;
  display: none;
  transition: display 0.5s ease-in;
  box-shadow: 0px -1px 6px -2px rgba(0, 0, 0, 0.72);
  -webkit-box-shadow: 0px -1px 6px -2px rgba(0, 0, 0, 0.72);
  -moz-box-shadow: 0px -1px 6px -2px rgba(0, 0, 0, 0.72);

  li {
    padding: 0.5rem;
    display: flex;
    gap: 0.8rem;
    cursor: pointer;
    font-size: 0.8rem;
  }
  li:hover {
    // background-color: gainsboro;
    background-color: #f4f7fa;
  }
  .icon-class {
    color: #17a2b8;
  }
}

// .options-menu {
//   .options-container {
//     display: none;
//   }
// }

// .options-menu:hover {
//   .options-container {
//     display: block;
//   }
// }
.show {
  display: block;
}
.slim {
  padding: 3.4px 4px !important;
}

.sm-text {
  font-size: 10px;
}
.md-text {
  font-size: 25px;
}

.none {
  display: none;
}
.block {
  display: block;
}

.withdrawal-viewonly{
  width: 80%;
  padding: 20px;
  position: sticky;
  top: 10px;
  border-radius: 15px;
//   -webkit-box-shadow: inset 10px 10px 20px -3px rgba(207,207,207,0.59);
// -moz-box-shadow: inset 10px 10px 20px -3px rgba(207,207,207,0.59);
// box-shadow: inset 10px 10px 20px -3px rgba(207,207,207,0.59);
-webkit-box-shadow: 10px 10px 22px -4px rgba(242,242,242,1);
-moz-box-shadow: 10px 10px 22px -4px rgba(242,242,242,1);
box-shadow: 10px 10px 22px -4px rgba(242,242,242,1);
border: 3.5px solid #17a2b8;

.flex-col{
  display: flex;
  flex-direction: column;
  font-size: 0.85rem;
}
.total{
  font-size: 0.9rem;
}
.flex {
  display: flex;
  gap: 5px;
  padding: 5px;;
}
.w-full{
  width: 100%;
}
.flex-1{
  width: 50%;
}

.text-danger{
  color:red
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: end;
}
.text-bold{
  font-weight: bold;
  vertical-align: middle;
}
.border-y{
  border: 1.5px solid lightgray;
  border-left: none;
  border-right: none;
}
.mb-5{
  margin-bottom: 5px;
}
.mb-15{
  margin-bottom: 20px;
}
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 768px) {
  div.loginleft {
    display: none;
  }
}
