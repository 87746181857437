/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
  padding: unset;
  width: unset;
}
.custom-toast-container {
  .Toastify__progress-bar {
    background: #001f54 !important; /* Change to your desired color */
  }
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {
}
.Toastify__toast-container--top-center {
}
.Toastify__toast-container--top-right {
}
.Toastify__toast-container--bottom-left {
}
.Toastify__toast-container--bottom-center {
}
.Toastify__toast-container--bottom-right {
}

/** Classes for the displayed toast **/
.Toastify__toast {
  padding: unset;
  margin: unset;
  min-height: unset;
  box-shadow: unset;
  border-radius: unset;
  margin-bottom: 5px;
}
.Toastify__toast--rtl {
}
.Toastify__toast--dark {
}
.Toastify__toast--default {
}
.Toastify__toast--info {
}
.Toastify__toast--success {
}
.Toastify__toast--warning {
}
.Toastify__toast--error {
}
.Toastify__toast-body {
  padding: unset;
  margin: unset;
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
  display: none;
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
}

/** Classes for the progress bar **/
.Toastify__progress-bar {
}
.Toastify__progress-bar--animated {
}
.Toastify__progress-bar--controlled {
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar--default {
}
.Toastify__progress-bar--dark {
}
