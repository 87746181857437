.flex {
  display: flex;
}
//already in app.scss
// .none {
//   display: none;
// }

.cursor {
  cursor: pointer;
}
